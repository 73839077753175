import Meta from "solar/components/Meta";
import Lead from "components/shared/stays/Lead";

export default function FourZeroFour() {
  return (
    <>
      <Meta title="404" path="/400" />
      <Lead>Dang it. Something went wrong.</Lead>
    </>
  );
}
