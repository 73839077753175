import PropTypes from "prop-types";
import cn from "classnames";
import styles from "components/shared/stays/Lead.module.scss";

export default function Lead(props) {
  const { className, children, fontStyle } = props;

  return (
    <div className={cn(styles.component, className, styles[fontStyle])}>
      {children}
    </div>
  );
}

Lead.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.node,
  fontStyle: PropTypes.string,
};

Lead.defaultProps = {
  className: null,
};
